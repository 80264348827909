<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="auto">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Үнэлгээний сан</h5>
        <p class="text-body">
          Сургуулийн үнэлгээний санг энд зоxион байгуулна.
        </p>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-capitalize
            ms-auto
            btn-primary
            bg-gradient-primary
            py-3
            px-6
            ms-3
          "
          @click="_createAssignmentDatabase(null)"
          >Үнэлгээ үүсгэx</v-btn
        >
      </v-col>
    </v-row>
    <v-row justify="start" v-if="assignments && assignments.length > 0">
      <v-col
        lg="4"
        md="6"
        cols="12"
        v-for="assignment in assignments"
        :key="assignment.id"
      >
        <UnelgeeCard
          :unelgee="assignment"
          :assignmentsTypes="assignmentsTypes"
          v-bind="$attrs"
          :lessonCategories="lessonCategories"
        ></UnelgeeCard>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <h5 class="text-center py-16 red--text">
        Одоогоор үнэлгээ байxгүй байна.
      </h5>
    </v-row>

    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline font-weight-bold"> Шинэ үнэлгээ үүсгэx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="lessonCategories"
                  item-text="name2"
                  item-value="id"
                  v-model="editedItem.selectedLessonCategory"
                  label="Ангилалт сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="[2022, 2023, 2024, 2025]"
                  v-model="editedItem.issuedYear"
                  label="Xичээлийн жил сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn class="btn-primary bg-gradient-primary" @click="saveItem">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import UnelgeeCard from "./UnelgeeCard.vue";

export default {
  components: { UnelgeeCard },
  data: () => ({
    downloadLoading: false,
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
    },
    messageNotification: null,
    newDialog: false,
    editedItem: {},
    defaultItem: {
      name: "",
      description: "",
    },
    query: null,
    headerNames: null,
    bodyNames: null,
    school: null,
    allLessons: null,
    classLessons: null,
    addd: false,
    assignments: null,
    assignmentsTypes: [
      // { value: 1, name: "Заавал" },
      // { value: 2, name: "Сонгон" },
      { value: 1, name: "Стандарт" },
      { value: 2, name: "ЭЕШ-шалгалт" },
    ],
    lessonCategories: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData ? this.userData.ppschool : null;
    },
  },
  created() {
    this._setupp();
  },
  methods: {
    _getCollectionPath() {
      if (this.zzschool) return this.zzschool + "/unelgeenuud";
    },
    _detail(item) {
      console.log(item);
    
    },
    _createAssignmentDatabase(item) {
      this.newDialog = true;
      if (item == null) {
        this.editedIndex = -1;
      }
    },
    async saveItem() {
      if (this.editedItem.name && this.editedItem.name.trim() !== "") {
        
        if (this.editedIndex === -1) {
          var x = await this.userData.ref.get();
          var firstName = null;
          if (x.data().firstName) {
            firstName = x.data().firstName;
          }
          if (this.zzschool != null) {
            var x2 = await fb.db.doc(this.zzschool).get();
            if (x2.exists) {
              this.editedItem.createdBySchoolName = x2.data().name;
              this.editedItem.createdBySchoolRef = x2.ref;

              this.editedItem.forAllSchool = false;
            }
          } else {
            this.editedItem.createdBySchoolName = null;
            this.editedItem.createdBySchoolRef = null;
            this.editedItem.forAllSchool = true;
          }
          this.editedItem.defaultAnswersCreated = false
          this.editedItem.deleted = false;
          this.editedItem.open = false;
          this.editedItem.setupped = false;
          this.editedItem.finished = false;
          this.editedItem.showingCorrectAnswers = false;
          this.editedItem.createdByRef = this.userData.ref;
          this.editedItem.createdAt = new Date();
          this.editedItem.createdByEmail = this.userData.email;
          this.editedItem.createdByFirstName = firstName;
          console.log(this.editedItem);
          fb.db
            .collection(this._getCollectionPath())
            .doc()
            .set(this.editedItem)
            .then(() => {
              console.log("Document xxxxx written!", this._getCollectionPath());
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
        } else {
          this.editedItem.modifiedAt = new Date();
          this.editedItem.ref.update(this.editedItem);
        }
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async _setupp() {
      fb.db
        .collection(this.lessonCategoriesPath)
        .orderBy("name", "asc")
        .onSnapshot((docs) => {
          this.lessonCategories = [];
          docs.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            item.name2 = item.name + " - " + item.lessonTypeName;
            this.lessonCategories.push(item);
          });
        });
      fb.db
        .collection(this._getCollectionPath())
        .orderBy("createdAt", "desc").where("deleted","==",false)
        .onSnapshot((docs) => {
          this.assignments = [];
          docs.forEach((doc) => {
            let assign = doc.data();
            assign.id = doc.id;
            assign.ref = doc.ref;
            // if(assign.forAllSchool==undefined)
            //   assign.ref.update({forAllSchool: true})
            this.assignments.push(assign);
          });
        });
    },
  },
};
</script>
 