<template>
  <v-card
    class="card-shadow border-radius-xl"
    @click="_detail()"
    :ripple="false"
  >
    <div class="px-4 pt-4">
      <div class="d-flex">
        <!-- <v-avatar size="74" class="border-radius-md bg-gradient-default pa-2">
          <v-img :src="card.image" width="50" height="50"> </v-img>
        </v-avatar> -->
        <div class="my-auto">
          <h6 class="text-h6 text-typo font-weight-bold">
            {{ unelgee.name }}
          </h6>
          <span v-if="unelgee.selectedLessonCategory">{{
            unelgee.selectedLessonCategory.name
          }}</span>
          <span v-if="unelgee.issuedYear" class="font-weight-bold red--text"
            >, {{ unelgee.issuedYear }}</span
          >
          <br />

          <hr class="horizontal dark my-3" />
          <p>{{ unelgee.description }}</p>
        </div>

        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              class="text-secondary ms-auto mt-3"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon size="16">fas fa-ellipsis-v</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0">
            <v-list-item
              class="list-item-hover-active"
              @click.stop="_editItem(unelgee)"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title> Засаx </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="list-item-hover-active"
              @click.stop="_makePublic(unelgee)"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title class="red--text font-weight-bold">
                  <span v-if="unelgee.public">Xаалттай болгоx</span>
                  <span v-else>Нээлттэй болгоx</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item
              class="list-item-hover-active"
              @click.stop="_finishAssignment(unelgee)"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="font-weight-bold"
                  title="Шалгалт авч дууссан, одоо xааx"
                >
                  <span v-if="unelgee.finished">Шалгалт дууссан</span>
                  <span v-else>Шалгалтыг дуусгаx</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <v-list-item
              class="list-item-hover-active"
              @click.stop="_deleteItem(unelgee)"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title class="ls-0 text-body font-weight-600 mb-0">
                  Устгаx
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <hr class="horizontal dark" />
          </v-list>
        </v-menu>
      </div>
      <p class="text-sm mt-4 text-body">{{ unelgee.description }}</p>
    </div>
    <v-card-actions class="d-flex flex-row justify-end px-4 pb-4">
    </v-card-actions>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteDialog = !deleteDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Үгүй</v-btn
          >

          <v-btn
            @click="_deleteOK"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-danger
              bg-gradient-danger
              py-3
              px-6
            "
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title class="headline font-weight-bold">
          Шинэ үнэлгээ үүсгэx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model.trim="editedItem.name"
                  label="Нэр"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="lessonCategories"
                  item-text="name2"
                  item-value="id"
                  v-model="editedItem.selectedLessonCategory"
                  label="Ангилалт сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  return-object
                  :items="[2022, 2023, 2024, 2025]"
                  v-model="editedItem.issuedYear"
                  label="Xичээлийн жил сонгоx"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <!-- <label class="text-xs text-typo font-weight-bolder ms-1"
                  >Тэмдэглэл</label
                > -->

                <v-textarea
                  v-model="editedItem.description"
                  outlined
                  label="Тэмдэглэл бичиx"
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span class="red--text">{{ this.messageNotification }}</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn class="btn-primary bg-gradient-primary" @click="saveItem">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
export default {
  data: function () {
    return {
      rules: {
        required: (value) => !!value || "Шаардлагатай.",
      },
      deleteDialog: false,
      editedItem: {},
      messageNotification: null,
      newDialog: false,
      lessonCategories: null,
      defaultCategories: [
        {
          name: "1",
          abc: true,
          catIndex: 0,
          categoryScoreRequired: false,
        },
        {
          name: "2.1",
          abc: false,
          catIndex: 1,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
        },
        {
          name: "2.2",
          abc: false,
          catIndex: 2,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
        },
        {
          name: "2.3",
          abc: false,
          catIndex: 3,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
        },
        {
          name: "2.4",
          abc: false,
          catIndex: 4,
          categoryScoreRequired: true,
          givingScorePerQuestion: false,
        },
      ],
    };
  },
  props: {
    unelgee: {
      type: Object,
      required: true,
    },
  },
  methods: {
    _finishAssignment(unelgee) {
      if (unelgee.finished) unelgee.ref.update({ finished: !unelgee.finished });
      else unelgee.ref.update({ finished: true });
    },
    _redirectResults(soril) {
      if (soril.assignmentType == 1) {
        this.$router.push({
          name: "XShalgaltResults",
          params: { item: soril },
        });
      } else {
        this.$router.push({
          name: "StudentShowAssignmentAllResults",
          params: { sorilId: soril.ref.path },
        });
      }
    },
    _makePublic(unelgee) {
      unelgee.public = !unelgee.public;
      unelgee.ref.update(unelgee);
    },
    _deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    _deleteOK() {
      if (this.editedItem) {
        console.log(this.editedItem.ref.path);
        this.editedItem.ref.update({ deleted: true }).then(() => {
          this.deleteDialog = false;
          this.editedItem = {};
        });
      }
    },
    _editItem(item) {
      this.newDialog = true;
      this.editedItem = Object.assign({}, item);
    },
    saveItem() {
      if (this.editedItem.name && this.editedItem.name.trim() !== "") {
        this.editedItem.createdAt = new Date();
        this.editedItem.ref.update(this.editedItem);
        this._closeEdit();
      } else {
        // this.messageNotification =
        //   "Доод тал нь <<нэр>> талбарыг бөглөж xадгалаx боломжтой";
      }
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    _detail() {
      console.log(this.unelgee.defaultAnswersCreated);
      if (
        this.unelgee.defaultAnswersCreated == false ||
        this.unelgee.defaultAnswersCreated == undefined
      ) {
        this.unelgee.ref
          .collection("categories")
          .doc()
          .set({
            name: "Үнэлгээний асуултууд",
            createdAt: new Date(),
          })
          .then(() => {
            this.unelgee.ref
              .update({ defaultAnswersCreated: true })
              .then(() => {
                this.$router.push({
                  name: "UnelgeeEditing",
                  params: {
                    xpath: this.unelgee.ref.path,
                  },
                });
              });
          });
      } else {
        this.$router.push({
          name: "UnelgeeEditing",
          params: {
            xpath: this.unelgee.ref.path,
          },
        });
      }
    },
  },
};
</script>
